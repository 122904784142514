<!--用户-->
<template>
  <div class="panel userList">
    <div class="serch_box">
      <el-form :inline="true" class="user-search fixed_form" size="mini">
        <el-form-item label="Discount rate type" size="mini">
          <template>
            <el-select
              v-model.trim="form.discountType"
              placeholder="Please Choose"
              size="mini"
            >
              <el-option
                v-for="item in contractTypeList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Enterprise Name">
          <template>
            <el-select v-model="enterpriseNameCode" popper-class="enterpriseNameClass" filterable placeholder="Please enter company name" @change="changeEnterpriseFn">
              <el-option
                v-for="item in EnterpriseList"
                :key="item.id"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
        <el-form-item label="Contract No" size="mini">
          <template>
            <el-input
              v-model.trim="form.contractNo"
              size="mini"
              placeholder="please enter Contract No"
              maxlength="100"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                style="width:30px"
                @click="showSku"
              />
            </el-input>
          </template>
        </el-form-item>
        <el-form-item label="Approval Status" size="mini">
          <template>
            <el-select
              v-model.trim="form.approvedState"
              placeholder="Please Choose"
              size=""
            >
              <el-option
                v-for="item in approvalStatusList"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              />
            </el-select>
          </template>
        </el-form-item>
      </el-form>
      <el-button type="" size="mini" @click="offBtn">Reset</el-button>
      <el-button
        type="primary"
        icon="el-icon-search"
        size="mini"
        @click="searchBtn"
      >
        Search
      </el-button>
    </div>
    <div class="panel-body">
      <el-table
        :data="tableList"
        fit
        style="width: 100%"
        size="mini"
        :header-cell-style="{ background: '#e8f1fe' }"
        @row-click="listClick"
      >
        <!-- 修改无数据时 提示文字 -->
        <template slot="empty" class="logo_no">
          <!-- <img :src="emptyImgSrc" alt="" width="118px" height="110px"> -->
          <p style="color: #969799">No more data</p>
        </template>
        <el-table-column
          label="No."
          width="100"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.id || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Discount Rate Type"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div
              v-for="(item, index) in contractTypeList"
              v-show="scope.row.discountType == item.value"
              :key="index"
            >
              {{ item.label || '--' }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Contract No"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.contractNo || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Enterprise Name"
          width="180"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.enterpriseName || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Submitter ERP"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.creator || '--' }}</span>
          </template>
        </el-table-column>
        <el-table-column
          label="Latest Approval Status"
          min-width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <div v-if="scope.row.approvedState != ''">
              <div
                v-for="(items, index) in approvalStatusList"
                :key="index"
              >
                <span v-if="items.value == scope.row.approvedState">{{
                  items.label
                }}</span>
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column
          label="Latest Submission Time"
          width="200"
          :show-overflow-tooltip="true"
        >
          <template slot-scope="scope">
            <span>{{ scope.row.modified | filterDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="Operate" width="140" fixed="right">
          <template slot-scope="scope">
            <el-button
              v-if="scope.row.approvedState != 1"
              type="text"
              @click="updata(scope.row)"
            >
              View
            </el-button>
            <el-button v-else type="text" @click="ditFun(scope.row)">
              Audit
            </el-button>
          </template>
        </el-table-column>
      </el-table>
      <div class="pageWrapper">
        <el-pagination
          :current-page="listQuery.pageCode"
          :page-sizes="[10, 20, 50, 100]"
          :page-size="listQuery.pageSize"
          layout="total, sizes, prev, pager, next, jumper"
          :total="listQuery.total"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
        />
      </div>
    </div>
    <el-dialog :visible.sync="dialogVisible">
      <div class="dialog-footer" style="margin-bottom: 20px">
        <el-button
          type="primary"
          @click="createData('postForm')"
        >
          confirm
        </el-button>
        <el-button
          
          type="primary"
          @click="updateData('postForm')"
        >
          confirm
        </el-button>
      </div>
    </el-dialog>
    <el-dialog title="Choice Contract No" :visible.sync="dialogSKU" width="50%">
      <Delotal @handleCurrentChanges="handleCurrentChanges" />
      <div slot="footer" class="dialog-footer">
        <el-button @click="dialogSKU = false">Cancel</el-button>
        <el-button
          
          type="primary"
          @click="updateDatas('postForm')"
        >
          Confirm
        </el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
// 引入API接口
import { getrecord, userUnBind } from '@/api/user/list';
import Delotal from '../contractManage/conttemplat.vue';
import dateFormate from '../../utils/date'
import { getEnterpriseName } from '@/api/enterprise/enterprise';
export default {
  // 引入局部刷新
  inject: ['reload'],
  components: {
    Delotal
  },
  filters: {
    filterDate(dateStr){
      return dateFormate(dateStr,'yyyy-MM-dd hh:mm:ss')
    }
  },
  data() {
    return {
      emptyImgSrc: '',
      // 配置分页信息
      listQuery: {
        pageSize: 10,
        pageCode: 1,
        total: 0 // 总页数
      },
      dialogVisible: false,
      // 搜索条件
      form: {
        discountType: '',
        contractNo: '',
        approvedState: '1',
        // enterpriseName: ''
      },
      // 字典表
      contractTypeList: [
        { value: '1', label: 'Category-General Discount Rate' },
        { value: '2', label: 'Category-Exclusive Discount Rate' },
        { value: '3', label: 'SKU-General Discount Rate' },
        { value: '4', label: 'SKU-Exclusive Discount Rate' }
      ],
      approvalStatusList: [
        { value: '', label: 'All' },
        { value: '1', label: 'Waiting for approval' },
        { value: '2', label: 'Approved' },
        { value: '3', label: 'Rejected' }
      ],
      // 后端获取表格信息
      tableList: [],
      // 选中列表信息项
      multipleSelection: '',
      // 定义tabel切换初始位置
      outeObject: this.$route.query,
      unbind: false,
      dialogSKU: false,
      multipleSelections: '',
      EnterpriseList: [],
      enterpriseNameCode: '',
    };
  },
  watch: {
    outeObject: function(val) {
      //console.log(val);
    }
  },
  created() {
    this.getFirstData();
    this.getEnterpriseNameData();
    this.emptyImgSrc = '';
  },
  methods: {
    // 切换企业名称，回显合同号
    changeEnterpriseFn(val) {
      this.form.contractNo = val;
    },
    // 根据企业名称查询 合同号
    getEnterpriseNameData() {
      getEnterpriseName().then(res => {
        if (res.code === 200) {
          var list = [];
          if (res.data && res.data.length > 0) {
            for (let i = 0; i < res.data.length; i++) {
              const element = res.data[i];
              if (element.contractNo != null) {
                list.push({
                  value: element.contractNo,
                  label: element.enterpriseName,
                  id: element.id
                });
              }
            }
            this.EnterpriseList = list;
          } else {
            this.EnterpriseList = [];
          }
        }
      });
    },
    showSku() {
      this.dialogSKU = true;
    },
    handleCurrentChanges(val) {
      this.multipleSelections = val;
    },
    updateDatas() {
      console.log(this.multipleSelections);

      this.form.contractNo = this.multipleSelections.contractNo;
      this.dialogSKU = false;
    },
    // 获取页面数据
    getFirstData() {
      // this.total = 1;
      getrecord({
        discountRecordDTO: Object.assign(
          this.form
        ),
        pageNo: this.listQuery.pageCode,
        pageSize: this.listQuery.pageSize
      }).then(res => {
        if (res.success && res.code === 200) {
          this.tableList = res.data.dataList;
          this.listQuery.total  = res.data.totalCount;
        } else {
          this.tableList = [];
          this.listQuery.total  = 0;
          this.$message({
            message: res.msg,
            type: 'error'
          });
        }
      });
    },
    // 新增
    addBtn() {
      this.$router.push({
        name: 'User Management New/edit',
        path: '/user/add'
      });
    },
    // 分页信息-设置条数
    handleSizeChange(val) {
      this.listQuery.pageSize = val;
      this.getFirstData();
    },
    // 分页信息-切换页数
    handleCurrentChange(val) {
      this.listQuery.pageCode = val;
      this.getFirstData();
    },
    // 搜索栏-搜索按钮
    searchBtn() {
      this.listQuery.pageCode = 1;
      this.getFirstData();
    },
    // 搜索栏-清空按钮
    offBtn() {
      this.reload();
      this.enterpriseNameCode = '';
    },
    updata(row) {
      row.flang = '2';
      this.$router.push({
        name: 'New discount rate',
        path: '/discountManage/addDiscount',
        query: {id:row.id,flang:row.flang,discountType:row.discountType,contractNo:row.contractNo }
      });
    },
    // 点击行时触发
    listClick(row) {
      console.log('row=========')
      this.multipleSelection = row;
    },
    // 修改按钮
    editBtn() {
      if (this.multipleSelection == '') {
        this.$confirm('Please Choose what you want to modify!', 'warning', {
          confirmButtonText: 'confirm',
          cancelButtonText: 'cancel',
          type: 'warning'
        })
          .then(() => {})
          .catch(function() {});
      } else {
        this.$router.push({
          name: 'change password',
          path: '/user/changePassword',
          query: { cont: this.multipleSelection }
        });
      }
    },
    ditFun(row) {
      row.flang = '2';
      this.$router.push({
        name: 'New discount rate',
        path: '/discountManage/addDiscount',
        query: {id:row.id,flang:row.flang,discountType:row.discountType,contractNo:row.contractNo }
      });
    },
    //解绑按钮
    unbindBnt(row) {
      this.$confirm('Whether to unbind the user', 'warning', {
        confirmButtonText: 'Yes',
        cancelButtonText: 'No',
        type: 'warning'
      })
        .then(() => {
          var obj = {
            pin: row.pin,
            contractNo: row.contractNo
          };
          userUnBind(obj).then(res => {
            //console.log(res);
            if (res.code == 200) {
              this.$message({
                message: 'Unbundling success',
                type: 'success'
              });
              setTimeout(() => {
                this.$router.push({
                  name: 'contract list',
                  path: '/contractManage/contractList'
                });
              }, 1000);
            } else {
              this.$message({
                message: res.msg,
                type: 'error'
              });
            }
          });
        })
        .catch(function() {});
    }
  }
};
</script>
<style lang="less" scoped>
.userList {
  .el-input {
    width: 200px;
    margin-right: 30px;
  }

  ::v-deep .el-pagination__editor.el-input {
    width: 50px;
  }
  .pageWrapper {
    margin: 10px 10px 10px 0;
    float: right;
    height: 45px;
  }
}
.heard_box {
  padding: 20px;
  box-sizing: border-box;
  display: flex;
  border-bottom: 1px solid #cccccc;
  margin-bottom: 10px;
  div {
    label {
      margin-right: 5px;
    }
  }
}
.el-button {
  font-size: 14px;
  ::v-deep .el-icon-search {
    font-size: 14px;
  }
}
.panel-body {
  padding: 0 16px;
}
.serch_box {
  width: 100%;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  background: #fff;
  padding: 20px;
  box-sizing: border-box;

  ::v-deep .el-input__inner {
    height: 32px;
    line-height: 30px;
  }
}
.logo_no {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

/deep/.el-form-item__label {
  font-size: 14px !important;
  font-family: ArialMT;
}
::v-deep .el-form-item__content {
  width: 230px;
}
/deep/.el-button {
  // padding: 0 12px !important;
  width: 100px;
  font-family: ArialMT;
  font-size: 14px;
}

::v-deep .el-button--primary {
  color: #fff;
  background-color: #2262ff;
  border-color: #2262ff;
}
::v-deep .el-button--text {
  color: #2262ff;
  margin-left: 0;
}

::v-deep .el-radio-group {
  .el-radio__label {
    color: #242526;
  }
  .is-checked {
    .el-radio__inner {
      background-color: #2262ff;
    }
    .el-radio__label {
      color: #242526;
    }
  }
}

::v-deep .el-table {
  .cell {
    white-space: pre-wrap;
    word-break: keep-all;
  }
}

::v-deep .el-pager {
  li {
    border: 0;
  }
}
</style>
